import React from "react";

const SelectedOrderd = ({ props }) => {
  console.log(props);

  return (
    <div className="bg-white w-full px-4 rounded-2xl py-2 transform duration-200 mt-2">
      <div className="text-sm font-semibold py-1">
        ORDER ID: {props.order_id}
      </div>

      <div className="mt-1 mb-2 space-y-2">
        {/* {selectedOrder.order_items.length !== 0 &&
          selectedOrder.order_item.map((item, i) => (
            <div key={i} className="border flex items-center gap-2">
              <img src={""} alt="" />
              <div></div>
            </div>
          ))} */}
        {props?.order_items?.map((item, i) => {
          return (
            <button
              key={i}
              className={`border w-full flex items-center gap-2 rounded-lg 
                bg-[#E3ECFF]`}
            >
              <img
                src={item.image_url}
                alt=""
                className="rounded-md w-[50px]"
              />
              <div className="py-1 px-1">
                <h1 className="font-semibold capitalize">
                  {item.product_name}
                </h1>
                <p className="text-secondary text-sm text-left">
                  ₹{item.product_price}
                </p>
              </div>
            </button>
          );
        })}
      </div>

      <p className="text-sm font-semibold">
        TOTAL: &nbsp;₹{props?.total_amount}
      </p>

      <div className="flex items-center justify-start gap-1 py-1 text-secondary">
        {/* <img src={orderSuccessImg} alt="" /> */}
        <p className="text-sm">
          {props?.order_items?.length} items delivered on &nbsp;
          {props?.order_date}
        </p>
      </div>
    </div>
  );
};

export default SelectedOrderd;
