import React, { useCallback, useEffect, useState } from "react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import micImage from "../assets/images/mic.svg";
import activeMic from "../assets/images/active mic.svg";
import Loader from "../component/shared/Loader";
import aiRepsongingImg from "../assets/images/airesponding.svg";


const Voice = () => {
  const [micOn, setMicOn] = useState(false);
  const [serviceUsed, setServiceUsed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userMsg, setUserMsg] = useState([]);
  const [responding, setResponding] = useState(false);
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();

  useEffect(() => {
    if (!browserSupportsSpeechRecognition) {
      alert("Browser doesn't support speech recognition.");
    }
    if (document.visibilityState !== "visible") {
      SpeechRecognition.abortListening();
      setMicOn(false);
    }
  }, [document.visibilityState]);

  useEffect(() => {
    if (micOn && browserSupportsSpeechRecognition) {
      resetTranscript();
      SpeechRecognition.startListening({ continuous: true });
      setServiceUsed(true);
    } else {
      SpeechRecognition.stopListening();
      // setResponding(true);
    }
  }, [micOn]);

  useEffect(() => {
    if (serviceUsed && !listening) {
      setMicOn(false);
      if (transcript.length > 0) {
        setUserMsg((prev) => [...prev, transcript]);
      }
      // setServiceUsed(false);
      fakeLoader();
    }
  }, [listening]);
  //   useEffect(() => {
  //     if (!listening && !micOn) { // Modify the condition here
  //       alert("Your voice has been recorded");
  //     }
  //   }, [listening, micOn]);
  console.log(transcript);
  console.log(userMsg);

  const showAiResponding = () => {
    setResponding(true);
    const timeOut = setTimeout(() => {
      setResponding(false);
      setServiceUsed(false);
    }, 3000);
    return () => clearTimeout(timeOut);
  };

  const fakeLoader = useCallback(() => {
    setIsLoading(true);
    const timeOut = setTimeout(() => {
      setIsLoading(false);
      if (serviceUsed && !listening) {
        showAiResponding();
      }
    }, 1500);
    return () => clearTimeout(timeOut);
  }, [serviceUsed, listening]);


  return (
    <div className="h-screen flex items-center justify-center gap-10 py-10">
      <div className="bg-white border shadow-md rounded-xl h-full w-[350px] flex flex-col items-center justify-center">
        {responding ? (
          <>
            <img src={aiRepsongingImg} alt="Ai" />
            <p className="text-lg mt-10">Ai Responding...</p>
          </>
        ) : (
          <>
            {" "}
            {!isLoading && (
              <>
                <button
                  onClick={() => {
                    setMicOn((prev) => !prev);
                    fakeLoader();
                  }}
                  className="flex items-center justify-center -mt-20"
                >
                  {micOn ? (
                    <img src={activeMic} alt="" className="" />
                  ) : (
                    <img src={micImage} alt="" />
                  )}
                </button>
                {listening ? (
                  <p className="text-lg text-center ">
                    Tap again to stop speaking
                  </p>
                ) : (
                  <p className="text-lg text-center ">Tap to speak</p>
                )}
              </>
            )}
          </>
        )}
        {isLoading && !responding && (
          <>
            <div className="-ml-6">
              <Loader />
            </div>
            <p className="text-lg mt-10">
              {serviceUsed && !listening
                ? "Generating response..."
                : "Connecting..."}
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default Voice;
