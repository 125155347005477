import { format, parse } from "date-fns";

const formatDate = (dateString) => {
  try {
    let formatString = "dd MMM";
    let parseFormat = "";

    if (dateString.includes("-")) {
      parseFormat = "dd-MM-yyyy";
    } else if (dateString.includes("/")) {
      parseFormat = "MM/dd/yyyy";
    } else {
      return "Invalid Date";
    }
    const date = parse(dateString, parseFormat, new Date());
    return format(date, formatString);
  } catch (error) {
    console.error("Invalid date format:", dateString);
    return null;
  }
};
export default formatDate;
