import React, { useEffect, useState } from "react";
import { collection, query, where, getDocs } from "firebase/firestore";
import robo from "../../assets/images/robo.svg";
import { db } from "../../config/firebase";
import successimg from "../../assets/icons/success.svg";
import arrowback from "../../assets/icons/arrow-back.svg";
import Loader from "../shared/Loader";
import Chatroom from "../chatroom";

const HistoryCard = ({ props }) => {
  const title = props.docId;
  const time = props.Messages[props.Messages.length - 1].server_timestamp;
  const message = props.Messages[props.Messages.length - 1];
  console.log(props);

  return (
    <article
      className="flex gap-2 border-b pb-2 rounded-xl cursor-pointer"
      onClick={() => props.setShowChatHistory(props.Messages)}
    >
      {true ? <img src={successimg} /> : <img src={robo} alt="" />}
      <div className=" space-y-2">
        <h1 className="flex items-center justify-between w-full text-[14px] font-medium">
          <span className="truncate w-[200px]"> {title}</span>{" "}
          <span className="text-[#949494] text-[12px]">{time}</span>
        </h1>
        <p className="text-[#949494] text-[12px]">{message.user}</p>
      </div>
    </article>
  );
};

const PrevChat = ({ setStartNewChat, showChatHistory, setShowChatHistory }) => {
  const session_id = localStorage.getItem("uid");
  const [messages, setMessages] = useState([]);
  const [showHistory, setShowHistory] = useState(false);
  // const [showChatHistory, setShowChatHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!session_id) {
      setStartNewChat(true);
    }
    const fetchData = async () => {
      setIsLoading(true);
      const q = query(
        collection(db, "users"),
        where("user_id", "==", session_id)
      );

      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        const extractedMessages = querySnapshot.docs.map((doc) => ({
          docId: doc.id,
          ...doc.data(),
        }));
        setMessages(extractedMessages);
      }
      if (querySnapshot) {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);
  console.log(showChatHistory);
  if (showChatHistory && showChatHistory.length > 0) {
    return (
      <>
        {/* {showChatHistory.map((chat, i) => {
          return (
            <div className="flex flex-col mt-4 gap-4 text-[#333333]" key={i}>
              {chat.server && (
                <div className="max-w-[80%] self-start">
                  <p className="bg-[#DCF7C5] text-[14px] p-3 rounded-2xl ">
                    {chat.server}
                  </p>
                  <p htmlFor="time" className="text-[10px] p-2">
                    {chat.time}
                  </p>
                </div>
              )}
              {chat.user && (
                <div className="self-end w-fit max-w-[80%]">
                  <p className="text-[14px] user-chatBg p-3 rounded-2xl ">
                    {chat.user}
                  </p>
                  <p
                    htmlFor="time"
                    className="text-[10px] leading-none text-right pt-2"
                  >
                    {chat.time}
                  </p>
                </div>
              )}
            </div>
          );
        })} */}
        <div className="flex flex-col justify-center ">
          <Chatroom sharedData={showChatHistory} userId={session_id} />
        </div>
      </>
    );
  }

  return (
    <div className="text-[#333333]">
      {!showHistory && (
        <>
          <h1 className="text-lg  font-medium">Start a new Conversation</h1>
          <div className="flex items-center pt-4 pl-4 gap-4">
            <img src={robo} alt="" />
            <p className="text-[14px]">
              Hi <span className="text-black">John!</span> I am here to assist
              you.
            </p>
          </div>
          <button
            className="selected-bg text-white w-full rounded-xl h-10 mt-4 text-lg font-medium active:scale-95"
            onClick={() => setStartNewChat(true)}
          >
            start chat
          </button>
          <div className="pt-4 flex items-center justify-between">
            <p className="font-medium">Your conversations</p>
            {messages.length > 0 && (
              <button
                className="text-primary underline"
                onClick={() => setShowHistory((prev) => !prev)}
              >
                View All {">"}
              </button>
            )}
          </div>
          {isLoading ? (
            <div className="mt-8">
              <Loader />
            </div>
          ) : (
            <div className="mt-4 space-y-2">
              {messages.length === 0 ? (
                <div className="flex items-center justify-center h-full">
                  No conversations to show
                </div>
              ) : (
                Object.values(messages)
                  .slice(messages.length - 2, messages.length)
                  .map((info, i) => {
                    console.log(info);
                    return (
                      <div key={i}>
                        <HistoryCard props={{ ...info, setShowChatHistory }} />
                      </div>
                    );
                  })
              )}
            </div>
          )}
        </>
      )}

      {showHistory && (
        <>
          <div className="text-lg  font-medium flex items-center gap-4">
            <button onClick={() => setShowHistory((prev) => !prev)}>
              <img src={arrowback} alt="" />
            </button>
            <h1>Conversation History</h1>
          </div>
          <div className="mt-4 space-y-2">
            {Object.values(messages)
              .reverse()
              .map((info, i) => {
                console.log(info);
                return (
                  <div key={i}>
                    <HistoryCard props={{ ...info, setShowChatHistory }} />
                  </div>
                );
              })}
          </div>
        </>
      )}
    </div>
  );
};

export default PrevChat;
