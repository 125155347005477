import React, { useEffect, useRef, useState } from "react";
import FilterTags from "./FilterTags";
import Chatroom from "../chatroom";
import Chatfooter from "../chatfooter";
import { useAuth } from "../context/authcontext";
import ChatBotHeader from "./Header";
import PrevChat from "./PrevChat";

const requestNotificationPermission = () => {
  if (
    Notification.permission !== "granted" ||
    Notification.permission !== "default"
  ) {
    Notification.requestPermission().then((permission) => {
      if (permission !== "granted") {
        alert("Notifications permission denied");
      }
    });
  }
};

const ChatBot = () => {
  const [newMessages, setNewMessage] = useState([]);
  // const { currentUser } = useAuth();
  const [startNewChat, setStartNewChat] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const chatRoomRef = useRef(null);
  const [showChatHistory, setShowChatHistory] = useState([]);

  const title = startNewChat ? "Chat Assistant" : "How may I help you";

  useEffect(() => {
    if (chatRoomRef.current) {
      // chatRoomRef.current.scrollTop = chatRoomRef.current.scrollHeight;
      chatRoomRef.current.scrollTop =
        chatRoomRef.current.scrollHeight - chatRoomRef.current.clientHeight;
    }
    console.log(Notification.permission);
    requestNotificationPermission();


    console.log("Gdsdgsd");
  }, [newMessages]);



  return (
    <div className="w-[360px] md:max-w-[360px] mx-auto border border-gray-100 bg-white rounded-3xl bg-[#E5EEFF33]">
      <div className="rounded-3xl p-1 font-[Poppins] text-[#333333] overflow-y-auto ">
        <ChatBotHeader title={title} />
        <main
          ref={chatRoomRef}
          className={`pt-5 pb-2 px-4 border-2 bg-white rounded-3xl border-gray-50  ${
            startNewChat ? "h-[350px]" : "h-[400px]"
          }  relative overflow-y-scroll no-scrollbar`}
        >
          {startNewChat && (
            <>
              {newMessages && newMessages.length !== 0 ? (
                <>
                  <div className="flex flex-col justify-center ">
                    <Chatroom sharedData={newMessages} userId={null} />
                  </div>
                </>
              ) : (
                <div className="px-4 h-full flex items-center justify-center text-lg ">
                  <h1 className="text-primary">
                    Hello there! I’m here to answer any questions you might
                    have. How can I assist you today?
                  </h1>
                </div>
              )}
            </>
          )}

          {!startNewChat && (
            <PrevChat
              setStartNewChat={setStartNewChat}
              showChatHistory={showChatHistory}
              setShowChatHistory={setShowChatHistory}
            />
          )}
        </main>
        {(startNewChat || showChatHistory.length !== 0) && (
          <footer className="bg-[#EFF3FD] text-primary px-1 pt-1 rounded-b-3xl">
            <Chatfooter
              messageupdate={setNewMessage}
              setIsLoading={setIsLoading}
              isLoading={isLoading}
              setShowChatHistory={setShowChatHistory}
              setStartNewChat={setStartNewChat}
            />
          </footer>
        )}
      </div>
    </div>
  );
};

export default ChatBot;
