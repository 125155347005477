import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyC8NWAbQyTb1XRq70gY7v2W2LUdvjBRlLg",
  authDomain: "open-source-28ce5.firebaseapp.com",
  projectId: "open-source-28ce5",
  storageBucket: "open-source-28ce5.appspot.com",
  messagingSenderId: "371280248581",
  appId: "1:371280248581:web:7a82e8bb17f778e888816c",
  measurementId: "G-21DBGPWW0V",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };
