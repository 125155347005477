import React from "react";
import "./chatuser.css";

function chatuser(props) {
  return (
    <div className="chat1">
      <div className="chat_text1">
        <p>{props.message.text}</p>
      </div>
      <div className="time_tag1">
        <p>{props.time}</p>
      </div>
    </div>
  );
}

export default chatuser;
