import React from "react";
import "./header.css";

import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  signOut,
} from "firebase/auth";

import { useAuth } from "./context/authcontext";

const auth = getAuth();

function Headtag(props) {
  const { currentUser } = useAuth();
  const { visible, setVisible } = props;
  let state = false;

  if (currentUser) {
    state = true;
  }
  const { signup123 } = useAuth();
  const signInWithGoogle = () => {
    const provider = new GoogleAuthProvider();

    signup123();
    /* signInWithPopup(auth, provider)
    .then((result) => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;
      // The signed-in user info.
      const user = result.user;
      console.log("user Details",user)
      // IdP data available using getAdditionalUserInfo(result)
      // ...
    }).catch((error) => {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.customData.email;
      // The AuthCredential type that was used.
      const credential = GoogleAuthProvider.credentialFromError(error);
      // ...
    });*/
  };
  console.log(props.SignIn);

  return (
    <div className="header">
      <div className="Logo_Element">
        <p>krunk.ai</p>
      </div>

      <div className="flex items-center gap-4">
        {state ? (
          <button className="buttonsignout" onClick={() => signOut(auth)}>
            Sign Out
          </button>
        ) : (
          <button className="buttonsignout" onClick={signInWithGoogle}>
            Sign In
          </button>
        )}

        {visible && (
          <button onClick={() => setVisible(false)}>
            <img src="https://firebasestorage.googleapis.com/v0/b/open-source-28ce5.appspot.com/o/web_icons%2Fclose.svg?alt=media&token=91144d3f-cbd4-47e8-abad-984744ff06ab" alt="close" />
          </button>
        )}
      </div>
    </div>
  );
}

export default Headtag;
