import React, { useRef, useState, useEffect } from "react";
import "./chatelement.css";
import Chatheader from "./chatheader.js";
import Chatroom from "./chatroom.js";
import Chatfooter from "./chatfooter.js";

import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  getDocs,
  addDoc,
  onSnapshot,
  orderBy,
  serverTimestamp,
  where,
  Timestamp,
  setDoc,
  getDoc,
  doc,
} from "firebase/firestore";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  signOut,
} from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { useAuth } from "./context/authcontext.js";

const firebaseConfig = {
  apiKey: "AIzaSyC8NWAbQyTb1XRq70gY7v2W2LUdvjBRlLg",
  authDomain: "open-source-28ce5.firebaseapp.com",
  projectId: "open-source-28ce5",
  storageBucket: "open-source-28ce5.appspot.com",
  messagingSenderId: "371280248581",
  appId: "1:371280248581:web:7a82e8bb17f778e888816c",
  measurementId: "G-21DBGPWW0V",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const auth = getAuth();

function Chatelement(props) {
  const { currentUser } = useAuth();

  const [messagesnew, setMessagesnew] = useState([]);

  var messages1 = [];
  messages1.push({
    server: true,
    text: "cancel my order",
    photoURL: "icon.png",
    uid: "",
  });

  return (
    <div>
      <React.Fragment>
        {currentUser != null ? (
          <div className="Parentchat">
            <div className="chatheader12">
              <Chatheader />
            </div>
            <div className="chatroom12">
              <Chatroom sharedData={messagesnew} />
            </div>
            <div className="sendbutton12>">
              <Chatfooter messageupdate={setMessagesnew} />
            </div>
          </div>
        ) : (
          <p>apple</p>
        )}
      </React.Fragment>
    </div>
  );
}

export default Chatelement;

//<React.Component>{ props.SignIn == true ? <div className='Parentchat'><div className='chatheader12'><Chatheader/></div><div className='chatroom12'><Chatroom sharedData={messages}/></div><div className='sendbutton12>'><Chatfooter onDatachange={handleDatachange}/></div></div>:<p>apple</p> }</React.Component>
