import "./chatfooter.css";
// import logo from "./Logo.svg";
import React, { useRef, useState, useEffect } from "react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  getDocs,
  addDoc,
  onSnapshot,
  orderBy,
  serverTimestamp,
  where,
  Timestamp,
  setDoc,
  getDoc,
  doc,
} from "firebase/firestore";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  signOut,
} from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
// import { useAuth } from "./context/authcontext";
import Loader from "../component/shared/Loader";
import homeIcon from "../home.svg";

const firebaseConfig = {
  apiKey: "AIzaSyC8NWAbQyTb1XRq70gY7v2W2LUdvjBRlLg",
  authDomain: "open-source-28ce5.firebaseapp.com",
  projectId: "open-source-28ce5",
  storageBucket: "open-source-28ce5.appspot.com",
  messagingSenderId: "371280248581",
  appId: "1:371280248581:web:7a82e8bb17f778e888816c",
  measurementId: "G-21DBGPWW0V",
};
const app = initializeApp(firebaseConfig);

const db = getFirestore(app);

const auth = getAuth();

function Chatfooter(props) {
  // const [micOn, SetMicOn] = useState(false);
  // const {
  //   transcript,
  //   listening,
  //   resetTranscript,
  //   browserSupportsSpeechRecognition,
  // } = useSpeechRecognition();

  // if (!browserSupportsSpeechRecognition) {
  //   return alert("Browser doesn't support speech recognition.");
  // }

  const currentTime = new Date();

  // Get hours, minutes, and AM/PM
  let hours = currentTime.getHours();
  const minutes = currentTime.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";

  // Convert to 12-hour format
  hours = hours % 12;
  hours = hours ? hours : 12; // Handle midnight (0 hours)

  // Format hours and minutes with leading zeros if necessary
  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = String(minutes).padStart(2, "0");

  // Combine hours, minutes, and AM/PM
  const currentTimeString = `${formattedHours}:${formattedMinutes} ${ampm}`;
  console.log("chatfooter called");
  console.log(currentTimeString);
  const [localdata, setlocaldata] = useState("");
  const [formValue, setFormValue] = useState("");
  // const { currentUser } = useAuth();
  // let { uid, photoURL } = currentUser;
  const { isLoading, setIsLoading, setShowChatHistory, setStartNewChat } =
    props;

  let photoURL = "testing";

  const handlechange = async (e) => {
    let uid = localStorage.getItem("uid") || "";
    // setShowChatHistory(false);
    // setStartNewChat(true);
    e.preventDefault();
    var docid = "";
    const storedData = localStorage.getItem("myData");
    if (!formValue) return;
    setIsLoading(true);
    setFormValue("Replying...");
    if (!storedData) {
      // If the data is not already stored, set the initial data
      const initialData = { key: uid + Date.now().toString() };

      localStorage.setItem("myData", JSON.stringify(initialData));
      console.log("Initial data stored:", initialData);
      docid = initialData.key;
    } else {
      // If the data is already stored, log it
      const parsedData = JSON.parse(storedData);
      console.log("Data already stored:", parsedData);
      docid = parsedData.key;
    }
    console.log(docid);

    const docRef = doc(db, "users", docid);
    // Use addDoc to add a new document to the "users" collection
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data());
      var message = docSnap.data().Messages;
      var lastmessage2 = message[message.length - 1];
      if (lastmessage2["server"] !== undefined) {
        message.push({ user: formValue, time: currentTimeString });
        await setDoc(docRef, {
          Messages: message,
          server: false,
          user_id: uid,
          photoURL,
        });
      } else {
        message.push({ user: formValue, time: currentTimeString });
        await setDoc(docRef, {
          Messages: message,
          server: false,
          user_id: uid,
          photoURL,
        });
      }
    } else {
      // docSnap.data() will be undefined in this case
      console.log("No such document!");
      await setDoc(docRef, {
        Messages: [{ user: formValue, time: currentTimeString }],
        server: false,
        user_id: uid,
        photoURL,
      });
    }
    const url = "https://chat-app-art-w4axif2xma-el.a.run.app/" + docid;
    // const url="https://chat-app-art-w4axif2xma-el.a.run.app/"+docid
    fetch(url)
      .then((hello) => {
        console.log("URL is fetched");
      })
      .catch((err) => {
        console.log("No check");
      })
      .finally(() => {
        setIsLoading(false);
        setFormValue("");
      });

    console.log("this is value", formValue);
    const newarray = [
      {
        server: false,
        text: formValue,
        photoURL: "icon.png",
        uid: uid,
        time: currentTimeString,
      },
    ];
    props.messageupdate(newarray);

    e.preventDefault();

    // setIsLoading(false);
  };

  // useEffect(() => {
  //   console.log(transcript);
  //   if (listening) {
  //     if (transcript && transcript.length > 0) {
  //       setFormValue(transcript);
  //       handlechange();
  //     }
  //   }
  // }, [listening]);
  // console.log(listening);
  // console.log(micOn);
  // console.log(transcript);
  return (
    <div className="bg-[#EFF3FD] text-[#4C82EF] px-2 pt-1 rounded-b-3xl">
      <div className="flex items-center justify-between gap-2">
        <img src={homeIcon} className="w-5 h-9" />
        <form
          className="flex items-center justify-between  px-1 rounded-lg w-[80%] bg-white"
          onSubmit={handlechange}
        >
          <input
            type="text"
            className="outline-none bg-transparent text-sm w-full h-8 text-black placeholder:text-sm px-3 disabled:pointer-events-none disabled:text-gray-400"
            placeholder="Type your message"
            onChange={(e) => setFormValue(e.target.value)}
            value={formValue}
            disabled={isLoading}
          />
          {isLoading && (
            <div className="mr-16">
              <Loader />
            </div>
          )}
          {/* <img
            src="https://firebasestorage.googleapis.com/v0/b/open-source-28ce5.appspot.com/o/web_icons%2Fclip.svg?alt=media&token=e1512749-9ee2-4065-ae71-f44f422f550c"
            alt=""
            className="w-4 mr-1.5 cursor-pointer"
          /> */}
          {/* <p> {listening ? "on" : "off"}</p> */}
          {/* <button onClick={() => SetMicOn((prev) => !prev)}>
            {micOn ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="#4C82EF"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="size-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 18.75a6 6 0 0 0 6-6v-1.5m-6 7.5a6 6 0 0 1-6-6v-1.5m6 7.5v3.75m-3.75 0h7.5M12 15.75a3 3 0 0 1-3-3V4.5a3 3 0 1 1 6 0v8.25a3 3 0 0 1-3 3Z"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="size-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 18.75a6 6 0 0 0 6-6v-1.5m-6 7.5a6 6 0 0 1-6-6v-1.5m6 7.5v3.75m-3.75 0h7.5M12 15.75a3 3 0 0 1-3-3V4.5a3 3 0 1 1 6 0v8.25a3 3 0 0 1-3 3Z"
                />
              </svg>
            )}
          </button> */}
        </form>
        <button
          className="w-[70px] flex items-center justify-center bg-[#4C82EF] rounded-xl h-8 disabled:opacity-65"
          onClick={handlechange}
          disabled={isLoading}
        >
          <img
            src="https://firebasestorage.googleapis.com/v0/b/open-source-28ce5.appspot.com/o/web_icons%2FbtnArrow.svg?alt=media&token=2e31246c-55c6-4403-ad77-083010516564"
            className="w-3 h-4"
            alt=""
          />
        </button>
      </div>
      <div className="flex items-center justify-center gap-1 text-sm pt-2 pb-1">
        <h1>
          <span className="text-[#B1B1B1]">Powered by</span>{" "}
          <span className="text-[#7A7F8C] font-semibold">Krunk.ai</span>{" "}
        </h1>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/open-source-28ce5.appspot.com/o/web_icons%2FLogo.svg?alt=media&token=5e4d9440-9ded-426e-be00-e82624f1b6f2"
          alt=""
          className="w-4"
        />
      </div>
    </div>
  );
}

export default Chatfooter;
