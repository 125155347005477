import React from "react";
import "./loader.css";

const Loader = ({ className }) => {
  return (
    <div className="flex items-center justify-center w-full">
      <div className="flex flex-col items-center rounded-lg bg-transparent">
        <div className="loader-dots relative block h-3 w-10">
          <div className="absolute top-0 mt-1 h-2 w-2 rounded-full bg-primary"></div>
          <div className="absolute top-0 mt-1 h-2 w-2 rounded-full bg-blue-400"></div>
          <div className="absolute top-0 mt-1 h-2 w-2 rounded-full bg-primary"></div>
          <div className="absolute top-0 mt-1 h-2 w-2 rounded-full bg-blue-400"></div>
        </div>
      </div>
    </div>
  );
};

export default Loader;
