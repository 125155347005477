import React, { useEffect, useState } from "react";
import { db } from "../../../config/firebase";
import { onSnapshot, setDoc, getDoc, doc } from "firebase/firestore";
import { Link, useNavigate } from "react-router-dom";

const SelectedProduct = ({ selectedProduct, setSelectedValue }) => {
  const [confirm, setConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const navigate = useNavigate();
  console.log(confirm);
  // console.log(selectedProduct.order_items);
  console.log(selectedProduct);
  const handleSelectChange = async (option) => {
    console.log(option);
    console.log("this is event", option);
    const product_id = option["product_id"];

    // navigate(selectedProduct?.product_link);
    // setIsLoading(true);
    // let c = option;
    // if (c != "choose orderid") {
    console.log("apple");
    const storedData = sessionStorage.getItem("myData");
    console.log(storedData);
    console.log("this is stored data", storedData);
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      console.log(parsedData);
      const docid = parsedData.key;
      const docRef = doc(db, "users", docid);
      const docSnap = await getDoc(docRef);
      var allmessage = [];
      console.log("this is inner value", option);
      console.log(docSnap);
      if (docSnap.exists) {
        allmessage = docSnap.data().Messages;
        var lastmessage = allmessage[allmessage.length - 1];
        lastmessage.Selected_Item = product_id;
        console.log(allmessage);
        allmessage[allmessage.length - 1] = lastmessage;
      }
      setDoc(
        docRef,
        {
          Messages: allmessage,
          Selected_Order: option,
        },
        { merge: true }
      )
        .then((hello) => {
          // const url="https://chat-app-art-w4axif2xma-el.a.run.app/"+docid
          const url = "https://chat-app-art-w4axif2xma-el.a.run.app/" + docid;
          fetch(url)
            .then((hello) => {
              console.log("URL is fetched", hello);
            })
            .catch((err) => {
              console.log("No check", err);
            })
            .finally(() => {
              setIsLoading(true);
            });
        })
        .catch(() => {
          console.log("Problem while setting up doc");
        });
    }
    // }
  };
  console.log(selectedProduct);
  return (
    <div className="bg-white  w-full px-2 rounded-2xl py-2 transform duration-200 mt-2">
      <div className="text-sm font-semibold py-1">
        PRODUCT ID: {selectedProduct?.product_id}
      </div>

      <div className="mt-1 mb-2 space-y-2">
        {/* {selectedProduct.order_items.length !== 0 &&
          selectedProduct.order_item.map((item, i) => (
            <div key={i} className="border flex items-center gap-2">
              <img src={""} alt="" />
              <div></div>
            </div>
          ))} */}
        {/* {selectedProduct &&
          selectedProduct?.order_items?.map((item, i) => {
            return (
              <div
                key={i}
                className={
                  "border w-full flex items-center gap-2 rounded-lg p-0 "
                }
              >
                <img
                  src={item.image_url}
                  alt=""
                  className="rounded-md w-[50px]"
                />
                <div className="">
                  <h1 className="font-semibold capitalize px-1">
                    {item.product_name}
                  </h1>
                  <p className="text-secondary text-sm text-left  px-1">
                    ₹{item.product_price}
                  </p>
                </div>
              </div>
            );
          })} */}
      </div>

      <p className="text-sm font-semibold">
        Price: &nbsp;₹{selectedProduct?.discounted_price}
      </p>

      <div className="flex items-center justify-start gap-1 text-secondary">
        <img
          src={selectedProduct?.img_link}
          alt=""
          className="w-[120px] mx-auto"
        />
      </div>
      <p className="text-sm py-2">{selectedProduct?.product_name}</p>
      <div className="flex items-center gap-2 justify-between mt-1">
        <button
          className="py-2 px-4 rounded-2xl border  border-primary text-primary w-fit font-semibold disabled:pointer-events-none"
          onClick={() => {
            setSelectedValue(null);
          }}
          disabled={confirm || isLoading}
        >
          Go Back
        </button>
        <a
          href={selectedProduct.product_link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <button
            className={`py-2 px-4 font-semibold rounded-2xl border text-white ${
              !confirm && "selected-bg"
            } flex-1 disabled:bg-gray-400 disabled:pointer-events-none`}
            onClick={() => {
              handleSelectChange(selectedProduct);
              setConfirm(true);
            
            }}
            // target="_blank"
            // to=""

            disabled={confirm || isLoading}
          >
            Select
          </button>
        </a>
      </div>
    </div>
  );
};

export default SelectedProduct;
