import React from "react";
import { Route, Routes } from "react-router-dom";
import App from "../App";
import Voice from "../pages/Voice";

const NavRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/voice" element={<Voice />} />
    </Routes>
  );
};

export default NavRoutes;
