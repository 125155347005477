import "google-fonts";

import Headtag from "./component/header.js";
import Bodycomponent from "./component/bodycomponent.js";
import { AuthProvider, useAuth } from "./component/context/authcontext.js";

import React, { useRef, useState, useEffect } from "react";
import "./App.css";
import btnLogo from "../src/assets/icons/toggleBtnlogo.svg";
import { Link } from "react-router-dom";

function App() {
  const [referringUrl, setReferringUrl] = useState("");
  const [visible, setVisible] = useState(true);
  // useEffect(() => {
  //   setReferringUrl(document.referrer);
  //   console.log("Reffering url", referringUrl);

  //   document.body.addEventListener("click", handleClickOutside);

  //   return () => {
  //     document.body.removeEventListener("click", handleClickOutside);
  //   };
  // }, []);

  // const handleClickOutside = (event) => {
  //   const chatbotButton = document.querySelector(".body-component button");
  //   const chatbotContainer = document.querySelector(".body-component");

  //   if (
  //     !event.target.closest(".body-component") &&
  //     event.target !== chatbotButton &&
  //     !chatbotContainer.contains(event.target)

  //   ) {
  //     setVisible(false);
  //   }
  // };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth); // State to hold window width

  useEffect(() => {
    if (windowWidth < 1024) {
      // Check window width state
      setVisible(true);
    }
  }, [windowWidth]); // Trigger effect when window width state changes

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth); // Update window width state on resize
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <AuthProvider>
      <header className="container px-4 sm:px-10 lg:px-20 border-b h-12 flex items-end">
        <div className="flex gap-1 items-center">
          <img
            src="/logo.svg"
            className="max-sm:mt-2 w-8 h-8 sm:w-12 sm:h-12 "
          />
          <h1 className="self-end text-lg sm:text-2xl text-primary font-bold">
            Krunk.ai
          </h1>
        </div>
      </header>
      <div className=" mt-5 sm:mt-8 2xl:mt-16">
        <div className="grid lg:grid-cols-3  lg:pt-4 container px-4 sm:px-8 lg:px-20 py-8 gap-3 overflow-hidden">
          <div className=" hidden lg:block cols-span-1 lg:col-span-2">
            <h1 className="text-[#333333] font-extrabold max-sm:leading-[3rem] text-3xl md:text-4xl lg:text-5xl">
              Meet your new AI chat assistant
            </h1>
            <p className="text-[#666666] text-lg  pt-6 !leading-8 ">
              Experience firsthand how our AI chatbot can provide personalized
              and engaging customer support.This sample chatbot showcases some
              of its key features and functionality.
            </p>
            <h2 className="text-lg text-primary font-semibold py-4">
              Sample Use Cases:
            </h2>
            <ul className="list-disc text-[#666666] text-base md:text-lg px-8">
              <li>Ask about product features and specifications.</li>
              <li>Track your order status and delivery details.</li>
              <li>Get recommendations for similar products you might like.</li>
              <li>Initiate a return or exchange request.</li>
              <li>Learn about upcoming promotions and discounts.</li>
            </ul>
            <p className="text-[#666666] sm:text-lg pt-8 !leading-8">
              See how chatbots by Krunk.ai can transform your customer service.
              <br />
              Explore our solution page for more information.
            </p>
            <a
              href={"https://krunk.ai/"}
              target="_blank"
              rel="noopener noreferrer"
              className="!bg-transparent max-xs:px-4 px-8 inline-block sm:px-10 text-primary border border-primary text-nowrap font-medium text-base sm:text-lg rounded-md py-2 mt-4"
            >
              Explore
            </a>
          </div>
          <main className="col-span-1 flex flex-col  justify-end w-full">
            {visible && <Bodycomponent />}
            <div className="flex justify-end pr-8 max-lg:hidden mt-2  ">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setVisible(!visible);
                }}
                className="z-[999] w-14 h-14 self-start"
              >
                <img
                  src={btnLogo}
                  alt="Ai bot"
                  className={`w-14 h-14  hover:border-primary ${
                    visible && "border-primary"
                  } ease-in-out transform duration-150 rounded-full `}
                />
              </button>
            </div>
          </main>
        </div>
      </div>
    </AuthProvider>
  );
}

export default App;
