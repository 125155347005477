import React, { useRef, useState, useEffect } from "react";
import {
  getFirestore,
  collection,
  getDocs,
  addDoc,
  onSnapshot,
  orderBy,
  serverTimestamp,
  where,
  Timestamp,
  setDoc,
  getDoc,
  doc,
} from "firebase/firestore";

import { db } from "../../../config/firebase";
import SelectedProduct from "./SelectedProduct";

function ProductsDisplay({ selectedProducts, selectionDisable }) {
  const [options, setOptions] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);
  const [hasSelection, setHasSelection] = useState(false);

  console.log(selectionDisable);
  useEffect(() => {
    if (selectedProducts && selectedProducts.length !== 0) {
      setOptions(selectedProducts);
    }
  }, []);
  console.log("called");
  console.log(options);

  const handleSelectChange = async (option) => {
    setSelectedValue(option);

    console.log(option);
    // setActiveId(option.order_id);
    console.log(selectedValue);

    setHasSelection(true);
    console.log("this is event", option);
    // let c = option;
    // if (c != "choose orderid") {
    //   console.log("apple");
    //   const storedData = sessionStorage.getItem("myData");

    //   console.log("this is stored data", storedData);
    //   if (storedData) {
    //     const parsedData = JSON.parse(storedData);

    //     const docid = parsedData.key;
    //     const docRef = doc(db, "users", docid);
    //     const docSnap = await getDoc(docRef);
    //     var allmessage = [];
    //     console.log("this is inner value", option);
    //     if (docSnap.exists) {
    //       allmessage = docSnap.data().Messages;
    //       var lastmessage = allmessage[allmessage.length - 1];
    //       lastmessage.action = JSON.parse(option);
    //       allmessage[allmessage.length - 1] = lastmessage;
    //     }
    //     setDoc(
    //       docRef,
    //       {
    //         Messages: allmessage,
    //         Selected_Order: option,
    //       },
    //       { merge: true }
    //     ).then((hello) => {
    //       // const url="https://chat-app-art-w4axif2xma-el.a.run.app/"+docid
    //       const url = "https://chat-app-art-w4axif2xma-el.a.run.app/" + docid;
    //       fetch(url)
    //         .then((hello) => {
    //           console.log("URL is fetched", hello);
    //         })
    //         .catch((err) => {
    //           console.log("No check");
    //         })
    //         .finally(() => {
    //           // setActiveId("");
    //         });
    //     });
    //   }
    // }

    /*if(event.target.value !="choose orderid"){
            console.log("this is event target value",event.target.value);
            console.log("this is seleced value",selectedValue);
            
            
            console.log("Option selected");
      
       }
        else{
            console.log("else called")
        }*/
  };
  console.log(options);
  console.log(selectionDisable);
  return (
    <div className="my-4">
      <div className="bg-gradient-to-r from-[#E5EEFF] to-[#E5EEFF66] w-full  rounded-2xl mt-5 p-1 pb-3">
        {selectedValue ? (
          <p className="text-sm px-4 pt-2">
            Which product do you need help with
          </p>
        ) : (
          <p className="text-start py-3 px-2 text-sm">Products</p>
        )}

        {!selectedValue ? (
          <div className="px-2 overflow-x-scroll  no-scrollbar disabled:pointer-events-none flex items-center justify-between gap-4 h-full">
            {options &&
              options.length !== 0 &&
              options.map((option, i) => {
                console.log(option.product_id);
                let active;
                if (selectedValue) {
                  active = option.product_id === selectedValue.product_id;
                }
                return (
                  <div
                    key={i}
                    disabled={hasSelection || selectionDisable}
                    className={` bg-white active:duration-150 ${
                      active && "bg-gray-400 text-white"
                    } flex items-center disabled:pointer-events-none  ${
                      !selectionDisable && "cursor-pointer"
                    } justify-between flex-wrap p-1 rounded-xl  self-start`}
                    onClick={
                      !selectionDisable
                        ? () => handleSelectChange(option)
                        : () => {}
                    }
                  >
                    <div className=" w-[180px] h-[185px] flex flex-col items-start self-start gap-2 mx-auto ">
                      <img
                        src={option?.img_link}
                        className="scale-90 h-[110px] rounded-md mx-auto aspect-auto"
                        alt={""}
                      />
                      <div className="text-xs px-2" key={i}>
                        <h1 className="capitalize line-clamp-3">{option.product_name}</h1>
                        <span className="text-start py-1 line-through font-semibold inline-block">
                          ₹ {option.actual_price}
                        </span>
                        <span className="ml-2 font-semibold">
                          ₹ {option.discounted_price}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        ) : (
          <div className="px-2">
            <SelectedProduct
              selectedProduct={selectedValue}
              setSelectedValue={setSelectedValue}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default ProductsDisplay;
