import React, { useContext, useEffect, useState } from "react";

import { auth } from "../firebase.js";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  signOut,
} from "firebase/auth";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();

  function signup123() {
    const provider = new GoogleAuthProvider();
    return signInWithPopup(auth, provider);
  }
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
    });

    return unsubscribe;
  }, []);

  const value = { currentUser, signup123 };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
