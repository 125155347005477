import "./chatroom.css";
import Chat from "./chat";
import { initializeApp } from "firebase/app";
// import Orderchoose from "./orderchoose";
import generateUniqueId from "../utils/generateId";

import Chatuser from "./chatuser";
import React, { useRef, useState, useEffect } from "react";
import { useAuth } from "./context/authcontext";
import {
  getFirestore,
  collection,
  getDocs,
  addDoc,
  onSnapshot,
  orderBy,
  serverTimestamp,
  where,
  Timestamp,
  setDoc,
  getDoc,
  doc,
} from "firebase/firestore";
import Orderchoosed from "./orderchoosed";
import Orderchoose from "./chatbot/queries/OrderChoose";
import ProductsDisplay from "./chatbot/queries/ProductDisplay";
import SelectedOrderItem from "./chatbot/queries/SelectedOrderItem";
import SelectedOrderd from "./chatbot/queries/SelectedOrderd";
import { db } from "../config/firebase";

function Chatroom(props) {
  // const { currentUser } = useAuth();
  console.log(props.sharedData);

  const [messages, setMessages] = useState(
    props.sharedData != [] ? props.sharedData : []
  ); // Fix the state variable name
  // const { uid, photoURL } = currentUser;
  const [uid, setUid] = useState(props.userId || null);
  console.log(uid);
  var docid = "";

  useEffect(() => {
    const existingUid = localStorage.getItem("uid");
    if (!existingUid) {
      const newUid = generateUniqueId();
      localStorage.setItem("uid", newUid);
      setUid(newUid);
    } else {
      setUid(existingUid);
    }
    const storedData = localStorage.getItem("myData");

    if (!storedData) {
      // If the data is not already stored, set the initial data
      const initialData = { key: uid + Date.now().toString() };

      localStorage.setItem("myData", JSON.stringify(initialData));
      console.log("Initial data stored:", initialData);
      docid = initialData.key;
    } else {
      // If the data is already stored, log it
      const parsedData = JSON.parse(storedData);
      console.log("Data already stored:", parsedData);
      docid = parsedData.key;
    }
    console.log(docid);

    const fetchData = async () => {
      console.log("fetchdata called");
      const docRef = doc(db, "users", docid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const docmessage = docSnap.data().Messages;
        console.log(messages);
        const transformedData = docmessage.flatMap((item) => {
          const result = [];

          if (item.server !== undefined) {
            if (item.Product == true) {
              result.push({
                server: false,
                text: item.user,
                photoURL: docSnap.data().photoURL,
                time: item.time,
              });
              result.push({
                server: true,
                Product: true,
                value: item.AllProducts,
                text: item.server,
                photoURL: docSnap.data().photoURL,
                time: item.server_timestamp,
                iShown: item.Selected_Item,
              });
            } else {
              result.push({
                server: false,
                text: item.user,
                photoURL: docSnap.data().photoURL,
                time: item.time,
              });
              result.push({
                server: true,
                text: item.server,
                photoURL: docSnap.data().photoURL,
                time: item.server_timestamp,
              });
            }
          } else if (item.user !== undefined) {
            if (item.Order !== undefined) {
              result.push({ server: false, text: item.user, Order: true });
            } else if (item.Product !== undefined) {
              result.push({
                server: false,
                text: item.user,
                photoURL: docSnap.data().photoURL,
                time: item.time,
              });
              result.push({
                server: false,
                Product: true,
                value: item.AllProducts,
                text: item.server,
                photoURL: docSnap.data().photoURL,
                time: item.time,
                iShown: item.Selected_Item,
              });
            } else {
              result.push({
                server: false,
                text: item.user,
                photoURL: docSnap.data().photoURL,
                time: item.time,
              });
            }
          }

          return result;
        });
        console.log("Ccaled");

        setMessages(transformedData);
      } else {
      }
    };

    fetchData();
    const docRef = doc(db, "users", docid);

    const unsubscribe = onSnapshot(docRef, (docSnap) => {
      console.log("unsubscribecalled");
      if (docSnap.exists()) {
        var data = docSnap.data();
        if (data !== undefined) {
          if ("Messages" in data) {
            const docmessage = docSnap.data().Messages;
            const transformedData = docmessage.flatMap((item) => {
              const result = [];

              if (item.server !== undefined) {
                if (item.Product == true) {
                  result.push({
                    server: false,
                    text: item.user,
                    photoURL: docSnap.data().photoURL,
                    time: item.time,
                  });
                  result.push({
                    server: true,
                    Product: true,
                    value: item.AllProducts,
                    text: item.server,
                    photoURL: docSnap.data().photoURL,
                    time: item.server_timestamp,
                    iShown: item.Selected_Item,
                  });
                } else if (item.action !== undefined) {
                  result.push({
                    server: false,
                    text: item.user,
                    photoURL: docSnap.data().photoURL,
                    time: item.time,
                  });

                  let object = JSON.parse(item.action);
                  result.push({
                    server: true,
                    text: item.user,
                    Order: true,
                    Value: object,
                    time: item.server_timestamp,
                  });

                  result.push({
                    server: true,
                    text: item.server,
                    photoURL: docSnap.data().photoURL,
                    time: item.server_timestamp,
                  });
                } else {
                  result.push({
                    server: false,
                    text: item.user,
                    photoURL: docSnap.data().photoURL,
                    time: item.time,
                  });
                  result.push({
                    server: true,
                    text: item.server,
                    photoURL: docSnap.data().photoURL,
                    time: item.server_timestamp,
                  });
                }
              } else if (item.user !== undefined) {
                if (item.Order !== undefined) {
                  result.push({
                    server: false,
                    text: item.user,
                    Order: true,
                    time: item.time,
                  });
                } else if (item.Product !== undefined) {
                  result.push({
                    server: false,
                    text: item.user,
                    photoURL: docSnap.data().photoURL,
                    time: item.time,
                  });
                  result.push({
                    server: false,
                    Product: true,
                    value: item.AllProducts,
                    text: item.server,
                    photoURL: docSnap.data().photoURL,
                    time: item.time,
                    iShown: item.Selected_Item,
                  });
                } else {
                  result.push({
                    server: false,
                    text: item.user,
                    photoURL: docSnap.data().photoURL,
                    time: item.time,
                  });
                }
              }

              return result;
            });
            setMessages(transformedData);
          }
        }
      } else {
        // The document doesn't exist
        console.log("Document does not exist");
      }
    });

    // Clean up the subscription on component unmount
    return () => unsubscribe();
  }, []);
  console.log(Notification.permission);

  function chatdisplay(value, index) {
    console.log("this is message value");
    console.log(value);

    if (value.server === true) {
      if (value.Product == true) {
        if (value.text != null) {
          console.log("product display");
          if (value.iShown == undefined) {
            return (
              <>
                <ProductsDisplay
                  selectedProducts={value.value}
                  selectionDisable={index < messages?.length - 1}
                />
                <Chat key={index} message={value} time={value.time}></Chat>
              </>
            );
          } else {
            return (
              <>
                <ProductsDisplay
                  selectedProducts={value.value}
                  selectionDisable={true}
                />
                <Chat key={index} message={value} time={value.time}></Chat>
              </>
            );
          }
        } else {
          console.log("product display");
          return (
            <>
              <ProductsDisplay selectedProducts={value.value} />
            </>
          );
        }
      }

      if (value.Value !== undefined) {
        console.log("selected called");
        return <SelectedOrderd props={value.Value} />;
      } else {
        console.log("true");

        return (
          <Chat key={index} message={value} time={value.time}>
            {" "}
          </Chat>
        );
      }
    } else {
      if (value.Order == true) {
        console.log(value);
        console.log("inner order call is being called");
        console.log(value);
        return (
          <React.Fragment>
            {/* <Chatuser key={index} message={value}></Chatuser> <Orderchoose /> */}
            <Chatuser key={index} message={value} time={value.time}>
              {" "}
            </Chatuser>{" "}
            <Orderchoose />
          </React.Fragment>
        );
      } else if (value.Product == true) {
        console.log("product display");
        if (value.iShown == undefined) {
          return (
            <>
              <ProductsDisplay
                selectedProducts={value.value}
                selectionDisable={index < messages?.length - 1}
              />
            </>
          );
        } else {
          return (
            <>
              <ProductsDisplay
                selectedProducts={value.value}
                selectionDisable={true}
              />
            </>
          );
        }
      } else {
        return (
          <Chatuser key={index} message={value} time={value.time}></Chatuser>
        );
      }
    }
  }
  useEffect(() => {
    if (Notification.permission === "granted") {
      if (
        messages.length !== 0 &&
        messages[messages.length - 1].server &&
        document.hidden
      ) {
        new Notification("New message received", {
          body: "You have a new message from krunk.ai chat.",
        });
      }
    }
  }, [messages]);
  console.log("this is chatdisplay original nessages", messages);

  // let newmessage = messages.map(chatdisplay);

  // console.log("this is newmessage", newmessage);
  // if (Notification.permission === "granted") {
  //   if (value.server === true) {
  //     if (document.hidden) {
  //       console.log("called");
  //       console.log(document.hidden);
  //       // Send a notification
  //       new Notification("New message received", {
  //         body: "You have a new message in the chat.",
  //       });
  //     }
  //   }
  // }
  return (
    <div className="py-2">
      {messages.map((message, index) => {
        return (
          <React.Fragment key={index}>
            {chatdisplay(message, index)}
          </React.Fragment>
        );
      })}
    </div>
  );
}

export default Chatroom;
