import React, { useEffect, useState } from "react";
import { db } from "../../../config/firebase";
import { onSnapshot, setDoc, getDoc, doc } from "firebase/firestore";

const SelectOrder = ({ selectedOrder, setSelectedValue }) => {
  const [confirm, setConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  console.log(confirm);
  console.log(selectedOrder.order_items);
  
  const handleSelectChange = async (option) => {
    console.log(option);
    console.log("this is event", option);
    setIsLoading(true);
    let c = option;
    if (c != "choose orderid") {
      console.log("apple");
      const storedData = localStorage.getItem("myData");

      console.log("this is stored data", storedData);
      if (storedData) {
        const parsedData = JSON.parse(storedData);
        console.log(parsedData);
        const docid = parsedData.key;
        const docRef = doc(db, "users", docid);
        const docSnap = await getDoc(docRef);
        var allmessage = [];
        console.log("this is inner value", option);
        console.log(docSnap);
        if (docSnap.exists) {
          allmessage = docSnap.data().Messages;
          var lastmessage = allmessage[allmessage.length - 1];
          lastmessage.action = JSON.stringify(option);
          allmessage[allmessage.length - 1] = lastmessage;
        }
        setDoc(
          docRef,
          {
            Messages: allmessage,
            Selected_Order: option,
          },
          { merge: true }
        )
          .then((hello) => {
            // const url="https://chat-app-art-w4axif2xma-el.a.run.app/"+docid
            const url = "https://chat-app-art-w4axif2xma-el.a.run.app/" + docid;
            fetch(url)
              .then((hello) => {
                console.log("URL is fetched", hello);
              })
              .catch((err) => {
                console.log("No check", err);
              })
              .finally(() => {
                setIsLoading(true);
              });
          })
          .catch(() => {
            console.log("Problem while setting up doc");
          });
      }
    }
  };
  return (
    <div className="bg-white w-full px-4 rounded-2xl py-2 transform duration-200 mt-2">
      <div className="text-sm font-semibold py-1">
        ORDER ID: {selectedOrder.order_id}
      </div>

      <div className="mt-1 mb-2 space-y-2">
        {/* {selectedOrder.order_items.length !== 0 &&
          selectedOrder.order_item.map((item, i) => (
            <div key={i} className="border flex items-center gap-2">
              <img src={""} alt="" />
              <div></div>
            </div>
          ))} */}
        {selectedOrder &&
          selectedOrder?.order_items?.map((item, i) => {
            return (
              <div
                key={i}
                className={
                  "border w-full flex items-center gap-2 rounded-lg p-0 "
                }
              >
                <img
                  src={item.image_url}
                  alt=""
                  className="rounded-md w-[50px]"
                />
                <div className="">
                  <h1 className="font-semibold capitalize px-1">
                    {item.product_name}
                  </h1>
                  <p className="text-secondary text-sm text-left  px-1">
                    ₹{item.product_price}
                  </p>
                </div>
              </div>
            );
          })}
      </div>

      <p className="text-sm font-semibold">
        TOTAL: &nbsp;₹{selectedOrder?.total_amount}
      </p>

      <div className="flex items-center justify-start gap-1 py-1 text-secondary">
        {/* <img src={orderSuccessImg} alt="" /> */}
        <p className="text-sm">
          {selectedOrder?.order_items?.length} items delivered on &nbsp;
          {selectedOrder?.order_date}
        </p>
      </div>
      <div className="flex items-center gap-2 justify-between mt-1">
        <button
          className="py-2 px-4 rounded-2xl border  border-primary text-primary w-fit font-semibold"
          onClick={() => {
            setSelectedValue(null);
          }}
        >
          Go Back
        </button>
        <button
          className={`py-2 px-4 font-semibold rounded-2xl border text-white ${
            !confirm && "selected-bg"
          } flex-1 disabled:bg-gray-400 disabled:pointer-events-none`}
          onClick={() => {
            handleSelectChange(selectedOrder);
            setConfirm(true);
          }}
          disabled={confirm || isLoading}
        >
          Confirm
        </button>
      </div>
    </div>
  );
};

export default SelectOrder;
