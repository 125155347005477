import React, { useRef, useState, useEffect } from "react";
import {
  getFirestore,
  collection,
  getDocs,
  addDoc,
  onSnapshot,
  orderBy,
  serverTimestamp,
  where,
  Timestamp,
  setDoc,
  getDoc,
  doc,
} from "firebase/firestore";
import { db } from "../../../config/firebase";
import downArrow from "../../../assets/images/downArrow.svg";
import orderSuccessImg from "../../../assets/images/ordersuccess.svg";
import formatDate from "../../../utils/formatDate";
import { v4 as uuidv4 } from "uuid";
import SelectedOrderItem from "./SelectedOrderItem";
import SelectOrder from "./SelectOrder";
import generateUniqueId from "../../../utils/generateId";

function Orderchoose(props) {
  const [options, setOptions] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);
  const [hasSelection, setHasSelection] = useState(false);
  const [activeId, setActiveId] = useState(undefined);
  const [uid, setUid] = useState("newuser123");
  //   const { currentUser } = useAuth();
  // const { uid } = currentUser;
  if (props.exists) {
    console.log("they exist");
  }

  useEffect(() => {
    const existingUid = localStorage.getItem("uid");
    if (!existingUid) {
      const newUid = generateUniqueId();

      localStorage.setItem("uid", newUid);
      setUid(newUid);
    } else {
      setUid(existingUid);
    }
    var docid = "";
    const storedData = localStorage.getItem("myData");
    console.log("useeffect called", storedData);

    if (!storedData) {
      // If the data is not already stored, set the initial data
      const initialData = { key: uid + Date.now().toString() };

      localStorage.setItem("myData", JSON.stringify(initialData));
      console.log("Initial data stored:", initialData);
      docid = initialData.key;
    } else {
      // If the data is already stored, log it
      const parsedData = JSON.parse(storedData);
      console.log("Data already stored:", parsedData);
      docid = parsedData.key;
    }
    const fetchData = async () => {
      const docRef = doc(db, "users", docid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const docmessage = docSnap.data().Orders;
        if (docmessage !== undefined) {
          if (Array.isArray(docmessage)) {
            setOptions(docmessage);
            console.log(docmessage);
          } else {
            console.error("Orders is not an array:", docmessage);
          }
        }
      }
    };

    fetchData();
    const docRef = doc(db, "users", docid);
    const unsubscribe = onSnapshot(docRef, (docSnap) => {
      if (docSnap.exists) {
        var data = docSnap.data();
        if (data !== undefined) {
          if ("Orders" in data) {
            const docmessage = docSnap.data().Orders;
            if (Array.isArray(docmessage)) {
              setOptions(docmessage);
            } else {
              console.error("Orders is not an array:", docmessage);
            }
          }
        }
      } else {
        // The document doesn't exist
        console.log("Document does not exist");
      }
    });
    return () => unsubscribe();
  }, []);

  const handleSelectChange = async (option) => {
    console.log(option);
    setActiveId(option.order_id);
    setSelectedValue(option);
    setHasSelection(true);
    console.log("this is event", option);
    let c = option;
    if (c != "choose orderid") {
      console.log("apple");
      const storedData = localStorage.getItem("myData");

      console.log("this is stored data", storedData);
      if (storedData) {
        const parsedData = JSON.parse(storedData);
        console.log(parsedData);
        const docid = parsedData.key;
        const docRef = doc(db, "users", docid);
        const docSnap = await getDoc(docRef);
        var allmessage = [];
        console.log("this is inner value", option);
        console.log(docSnap);
        if (docSnap.exists) {
          allmessage = docSnap.data().Messages;
          var lastmessage = allmessage[allmessage.length - 1];
          lastmessage.action = JSON.stringify(option);
          allmessage[allmessage.length - 1] = lastmessage;
        }
        setDoc(
          docRef,
          {
            Messages: allmessage,
            Selected_Order: option,
          },
          { merge: true }
        )
          .then((hello) => {
            // const url="https://chat-app-art-w4axif2xma-el.a.run.app/"+docid
            const url = "https://chat-app-art-w4axif2xma-el.a.run.app/" + docid;
            fetch(url)
              .then((hello) => {
                console.log("URL is fetched", hello);
              })
              .catch((err) => {
                console.log("No check", err);
              })
              .finally(() => {
                // setActiveId("");
              });
          })
          .catch(() => {
            console.log("Problem while setting up doc");
          });
      }
    }

    /*if(event.target.value !="choose orderid"){
            console.log("this is event target value",event.target.value);
            console.log("this is seleced value",selectedValue);
            
            
            console.log("Option selected");
      
       }
        else{
            console.log("else called")
        }*/
  };

  // console.log(options);
  console.log(selectedValue);
  console.log(activeId);
  console.log(options);
  return (
    <div>
      <div className="order-bg w-full  rounded-2xl my-5 pt-1 px-3 pb-3">
        {!selectedValue ? (
          <p className="py-2 ">Which item do you need help with?</p>
        ) : (
          <p className="py-2 ">Confirm your order</p>
        )}

        {!selectedValue && options.length !== 0 && (
          <div className="flex items-center gap-2 ">
            <p>
              Showing:
              <span className="ml-2">Last {options.length} orders</span>
            </p>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/open-source-28ce5.appspot.com/o/web_icons%2FdownArrow.svg?alt=media&token=6b068593-5c57-4be8-9eb2-520ea57b3ea4"
              alt="open"
            />
          </div>
        )}
        {selectedValue ? (
          <SelectOrder
            selectedOrder={selectedValue}
            setSelectedValue={setSelectedValue}
          />
        ) : (
          <div className="space-y-2 mt-2">
            {options &&
              options.length !== 0 &&
              options.map((option, i) => {
                const isActive = option?.order_id === activeId;
                return (
                  <div key={i} className="space-y-2">
                    <button
                      className="mx-auto rounded-xl w-full flex items-center justify-between bg-white px-4 py-3 text-sm"
                      onClick={() => setActiveId(option.order_id)}
                    >
                      <span className="font-semibold">
                        ₹ {option.total_amount}
                      </span>
                      <span className="text-secondary text-[12px]">
                        {option.order_items.length} items,
                        {option.order_items.order_status === "not-shipped"
                          ? "Ordered on"
                          : "Delivered"}
                        &nbsp;
                        {formatDate(option.order_date)}
                      </span>
                      <img
                        src={
                          "https://firebasestorage.googleapis.com/v0/b/open-source-28ce5.appspot.com/o/web_icons%2FdownArrow.svg?alt=media&token=6b068593-5c57-4be8-9eb2-520ea57b3ea4"
                        }
                        alt="^"
                      />
                    </button>

                    {isActive && (
                      <div className="bg-white w-full px-4 rounded-2xl py-2 transform duration-200">
                        <div className="text-sm font-semibold flex items-center justify-between">
                          ORDER ID: {option?.order_id}
                          <img
                            src={
                              "https://firebasestorage.googleapis.com/v0/b/open-source-28ce5.appspot.com/o/web_icons%2FdownArrow.svg?alt=media&token=6b068593-5c57-4be8-9eb2-520ea57b3ea4"
                            }
                            alt=""
                            onClick={() => setActiveId(undefined)}
                            className={"cursor-pointer rotate-180"}
                          />
                        </div>
                        <div className="flex items-center gap-2 py-2">
                          {option.image_url?.map((url, i) => (
                            <img
                              src={url}
                              key={i}
                              className="rounded-lg w-[50px]"
                              alt={i + "product"}
                            />
                          ))}
                        </div>
                        <p className="font-semibold">₹ {option.total_amount}</p>
                        <div className="flex items-center justify-start gap-1 py-1 text-secondary">
                          <img src={orderSuccessImg} alt="" />
                          <p className="text-sm">
                            {option.order_items.length} items{" "}
                            {option.order_items.order_status === "not-shipped"
                              ? "Ordered on"
                              : "Delivered"}
                            on {option.order_date}
                          </p>
                        </div>
                        <button
                          className="my-2 py-2 px-4 rounded-xl border font-semibold border-primary text-primary w-full"
                          onClick={() => setSelectedValue(option)}
                        >
                          View Items
                        </button>
                      </div>
                    )}
                  </div>
                );
              })}
          </div>
        )}
      </div>
    </div>
  );
}

export default Orderchoose;
