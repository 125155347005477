import React, { useState } from "react";

const ChatBotHeader = ({ title }) => {
  return (
    <header className="flex justify-between items-center gap-4 rounded-t-3xl bg-[#EFF3FD] text-primary px-4 py-3">
      <div className="flex items-center gap-2 w-full">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/open-source-28ce5.appspot.com/o/web_icons%2Fbot.svg?alt=media&token=d641dfbc-97dd-49df-84ce-da3094eb798a"
          alt=""
        />
        <div className="w-full">
          <div className="flex items-center gap-3">
            <h1 className="text-lg font-semibold leading-7">Denver</h1>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/open-source-28ce5.appspot.com/o/web_icons%2FVector.svg?alt=media&token=d6bd16d8-68a2-4670-a276-01c967f37432"
              alt=""
            />
          </div>
          <div className="flex items-center justify-between w-full">
            <h2 className="text-sm">{title}</h2>
            <div className="flex items-center gap-1">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/open-source-28ce5.appspot.com/o/web_icons%2FEllipse%2025.svg?alt=media&token=633aa347-9862-407f-9144-131e59ba106f"
                alt=""
                className=""
              />
              <h3 className="text-sm">Online</h3>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default ChatBotHeader;
