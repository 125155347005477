import React, { useRef, useState, useEffect } from "react";
import { useAuth } from "./context/authcontext";

import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  getDocs,
  addDoc,
  onSnapshot,
  orderBy,
  serverTimestamp,
  where,
  Timestamp,
  setDoc,
  getDoc,
  doc,
} from "firebase/firestore";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  signOut,
} from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";

function Orderchoosed(props) {
  return (
    <div className="chat">
      <select
        id="dropdown"
        value={"Apple"}
        disabled={true}
        className="chat_text"
      >
        <option value={"Apple"}>{props.value}</option>
      </select>
    </div>
  );
}

export default Orderchoosed;
