import React from "react";
import "./bodycomponent.css";
import Leftbodycomponent from "./leftbodycomponent.js";
import Chatelement from "./chatelement.js";
import ChatBot from "./chatbot/ChatBot.jsx";

function bodycomponent(props) {
  return (
    <div className="px-2 max-md:mt-8">
    
      <ChatBot />
    </div>
  );
}

export default bodycomponent;
